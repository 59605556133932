<template>
  <div>
    <div v-if="!papelModal" class="d-flex mb-8">
      <div class="titulo-pagina">
        {{ tituloFormulario }}
      </div>
    </div>
    <v-form ref="form" class="row">
      <input-text v-model="form.nome" class="col-12 col-md-3" :label="$t('modulos.papel.formulario.nome')" obrigatorio
        :max="150" trim />
      <input-text v-model="form.descricao" class="col-12 col-md-7" :label="$t('modulos.papel.formulario.observacao')"
        :max="500" />
      <input-select v-model="form.flagAtivo" :options="opcoes.booleano" class="col-12 col-md-2"
        :label="`${$t('modulos.papel.formulario.flagAtivo')} *`" />
      <accordion-padrao :titulo="$t('modulos.papel.formulario.permissoes')" :nao-retrair="true" class="col-12"
        cor-azul-texto>
        <div class="col-12">
          <div class="d-flex justify-space-between align-end">
            <span>
              <input-select v-model="form.funcionalidade" :options="opcoes.funcionalidade"
                :label="$t('modulos.papel.formulario.funcionalidade')" :placeholder="$t('geral.inputs.selecione')"
                background-color="white" :clearable="true" />
            </span>
            <div class="d-flex align-end">
              <span class="p-input-icon-left mr-4">
                <i class="pi pi-search" />
                <InputText v-model="filtroInput" placeholder="Pesquisar" size="small" style="border-radius: 8px"
                  @keyup.enter="chamarFiltrarEFiltro" />
              </span>
              <span v-if="id">
                <botao-padrao v-if="!exibirSelecionados" class="ml-auto" @click="alterarExibicaoPermissoes">
                  {{ $t('geral.botoes.exibir_selecionados') }}
                </botao-padrao>
                <botao-padrao v-if="exibirSelecionados" class="ml-auto" @click="alterarExibicaoPermissoes">
                  {{ $t('geral.botoes.exibir_todos') }}
                </botao-padrao>
              </span>
            </div>
          </div>

          <tabela-padrao-prime-vue ref="tabela" v-model="tabela.selecionados" class="mt-2" :colunas="tabela.colunas"
            :dados="tabela.dados" :mostrar-acoes="false" :mostrar-seletor="!ehPapelAdministrador"
            :quantidade-itens="tabela.quantidadeItens" :por-pagina="tabela.porPagina"
            :quantidade-paginas="tabela.quantidadePaginas" :pagina="tabela.paginaAtual" @paginar="listarRegistros"
            @filtrarGlobal="filtrarGlobal" @filtroGlobal="filtroGlobal">
            <template v-slot:seletor="{ slotProps }">
              <div>
                <v-simple-checkbox v-model="slotProps.data.isSelected" @input="
      selecionarFuncionalidadeAcao(
        slotProps.data,
        slotProps.data.isSelected
      )
      " />
              </div>
            </template>
          </tabela-padrao-prime-vue>
        </div>
      </accordion-padrao>
    </v-form>

    <v-divider class="my-5" />
    <div v-if="!papelModal" class="d-flex align-center justify-end">
      <botao-padrao outlined color="secondary" class="mr-2" @click="cancelar">
        <v-icon>$mdiCancel</v-icon>
        {{ $t('geral.botoes.cancelar') }}
      </botao-padrao>
      <botao-padrao @click="confirmacaoSalvar">
        <v-icon>$mdiContentSaveOutline</v-icon>
        {{ $t('geral.botoes.salvar') }}
      </botao-padrao>
    </div>
  </div>
</template>
<script>
import PapelService from '@common/services/cadastros/PapelService';
import PermissaoService from '@common/services/cadastros/PermissaoService';
import { PapelModel } from '@common/models/cadastros/PapelModel';
import helpers from '@common/utils/helpers';
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import BotaoPadrao from '../../../../components/buttons/BotaoPadrao.vue';
import EnumeradorService from '@common/services/cadastros/EnumeradorService';
export default {
  components: { BotaoPadrao },
  props: {
    id: { type: String, default: '' },
    papelModal: { type: Boolean, default: false },
  },
  data() {
    return {
      tabela: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'funcionalidadeTraducao',
            text: this.$t('modulos.papel.tabela.editar.funcionalidade'),
            sortable: true,
          },
          {
            value: 'funcionalidadeAcao',
            text: this.$t('modulos.papel.tabela.editar.funcionalidadeAcao'),
            sortable: true,
            formatter: (v) => {
              if (v) return this.$t(`enums.EnumFuncionalidadeAcao.${v}`);
            },
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
      },
      valido: false,
      form: new PapelModel({}),
      opcoes: {
        booleano: helpers.BoleanoEnum,
        funcionalidade: [],
      },
      exibirSelecionados: false,
      permissoes: null,
      dadosPermissoes: null,
      permissoesSelecionadas: [],
      filtroInput: '',
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        funcionalidade: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
      },
      filtroAplicado: { filter: '' },
      ehPapelAdministrador: false,
    };
  },
  computed: {
    tituloFormulario: function () {
      if (this.id) return this.$t('modulos.papel.titulos.editar');
      return this.$t('modulos.papel.titulos.novo');
    },
    dadosFiltrados: function () {
      if (!this.filters['global'].value) {
        return this.permissoes;
      }

      const filtro = this.filters['global'].value;

      const filtrados = this.permissoes.filter((item) =>
        item.funcionalidadeTraducao.includes(filtro)
      );

      return filtrados;
    },
  },
  watch: {
    form: {
      handler() {
        this.$emit('form-papel', this.form);
      },
      immediate: true,
    },
    'form.funcionalidade': {
      handler() {
        this.listarRegistros();
      },
      immediate: true,
    },
    'tabela.selecionados': {
      handler() {
        this.form.papeisPermissoes = this.tabela.selecionados.map(
          (a) => (a = { permissaoId: a.id })
        );
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    this.listarFuncionalidade();
    this.listarRegistros();
    if (this.id) {
      this.buscar(this.id);

      helpers.redirecionarSemPermissao(this, 'Papel', 'Editar');
    }
    helpers.redirecionarSemPermissao(this, 'Papel', 'Inserir');

    this.buscarPermissoes();
    if (!this.id) this.form.executaCalibracao = this.opcoes.booleano[1].value;
    this.$store.dispatch('Layout/alterarTituloPagina', this.tituloFormulario);
  },
  methods: {
    chamarFiltrarEFiltro: function () {
      this.filtroGlobal(this.filtroInput);
      this.filtrarGlobal();
    },
    listarFuncionalidade: function () {
      EnumeradorService.buscar('EnumFuncionalidade').then(
        (res) => {
          let funcionalidades = res.sort(this.compareText);
          this.opcoes.funcionalidade = funcionalidades.filter(f => this.existeFuncionalidade(f))

        }
      );
    },
    compareText(a, b) {
      const textA = a.text.toUpperCase().trim();
      const textB = b.text.toUpperCase().trim();
      return textA.localeCompare(textB, 'pt-BR', { sensitivity: 'accent' });
    },
    existeFuncionalidade(funcionaldiade) {
      return helpers.FuncionalidadesEnum.some(
        (el) => el.value == funcionaldiade.value
      )
    },
    confirmacaoSalvar: function () {
      this.confirmarSalvar().then(() => {
        this.salvar();
      });
    },
    buscar: function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      PapelService.buscar(this.id)
        .then((res) => {
          const data = res.data;
          this.form = new PapelModel(res.data);

          this.valido = true;
          if (data.flagAdministrador === true) this.ehPapelAdministrador = true;
        })
        .catch(() => {
          this.toastErro(this.$t('modulos.papel.erros.id_invalido'));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    listarRegistros: async function (
      paginaAtual = this.tabela.paginaAtual,
      porPagina = this.tabela.porPagina
    ) {
      this.tabela.porPagina = porPagina;
      let parametros;

      parametros = {
        papelId: this.id,
        funcionalidade: this.form.funcionalidade,
        sort: 'funcionalidade',
        page: paginaAtual,
        exibirSelecionados: this.exibirSelecionados,
        amountPerPage: porPagina,
        ...this.filtroAplicado,
      };
      if (!parametros.funcionalidade)
        delete parametros.funcionalidade;

      this.$store.dispatch('Layout/iniciarCarregamento');
      await PermissaoService.listar(parametros)
        .then((res) => {
          this.data = res.data.items;

          this.tabela.quantidadeItens = res.data.totalItems;
          this.tabela.quantidadePaginas = res.data.totalPages;
          this.permissoes = this.data.map(
            (permissao) =>
            (permissao = {
              ...permissao,
              funcionalidadeTraducao:
                this.descricaoFuncionalidadeTraducao(permissao),
            })
          );
          this.tabela.dados = this.permissoes;
          this.traduzirDeletarExcluir(this.permissoes);

          setTimeout(() => {
            this.iniciarForm();
          }, 300);
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    salvar: function () {
      if (this.$refs.form) this.valido = this.$refs.form.validate();
      if (!this.valido) return;
      this.$store.dispatch('Layout/iniciarCarregamento');

      PapelService.salvar(this.form.request)
        .then(() => {
          this.$router.push({ name: 'papel' });
          this.toastSucesso(this.$t(`modulos.papel.cadastro_sucesso`));
        })
        .catch((err) => {
          this.toastErro(this.$t(`geral.erros.${err.response.data.errors[0]}`));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
          this.valido = false;
        });
    },
    cancelar: function () {
      this.confirmarCancelar().then(() => {
        this.$router.push({ name: 'papel' });
      });
    },
    iniciarForm() {
      if (this.form.papeisPermissoes?.length > 0 && this.dadosPermissoes) {
        const filtroArray = this.dadosPermissoes.filter((obj) =>
          this.form.papeisPermissoes.some(
            (papelPermissao) => papelPermissao.permissaoId == obj.id
          )
        );

        this.permissoesSelecionadas = filtroArray.map(
          (permissao) =>
          (permissao = {
            ...permissao,
            funcionalidadeTraducao:
              this.descricaoFuncionalidadeTraducao(permissao),
          })
        );
        this.tabela.selecionados = this.permissoesSelecionadas;
        if (this.exibirSelecionados)
          this.tabela.dados = this.tabela.dados.concat(this.permissoesSelecionadas.filter(f => !this.tabela.dados.some(s => s.id == f.id) ));

      }
    },
    alterarExibicaoPermissoes() {
      this.exibirSelecionados = !this.exibirSelecionados;
      if (this.exibirSelecionados && this.permissoesSelecionadas.length <= 0)
        return this.iniciarForm();
      this.listarRegistros();
    },
    descricaoFuncionalidadeTraducao(permissao) {
      return helpers.FuncionalidadesEnum.find((el) => el.value == permissao.funcionalidade)?.text;
    },
    async buscarPermissoes(paginaAtual = 1, porPagina = 9999) {
      let parametros = {
        page: paginaAtual,
        amountPerPage: porPagina,
      };
      await PermissaoService.listar(parametros).then((res) => {
        this.dadosPermissoes = res.data.items;
      });
    },
    traduzirDeletarExcluir(arrayPermissoes) {
      this.permissoes = arrayPermissoes.map((p) => {
        if (p.funcionalidadeAcao == 'Deletar') {
          p.funcionalidadeAcao = 'Excluir';
        }
        return p;
      });

      return this.permissoes;
    },
    selecionarFuncionalidadeAcao(item, selecionado) {
      if (!selecionado) {
        this.tabela.selecionados.splice(
          this.tabela.selecionados.indexOf(
            this.tabela.selecionados.find((el) => el.id == item.id)
          ),
          1
        );
      } else {
        this.tabela.selecionados.push(item);
      }
    },
    /* selecionarPermissao(permissao) {
      if (this.tabela.selecionados.some((el) => el.id == permissao.id))
        this.excluirPermissao(permissao);
      else this.tabela.selecionados.push(permissao);
    },
    excluirPermissao: function (permissao) {
      if (this.tabela.selecionados.some((el) => el.id === permissao.id)) return;
      this.instrumentoAdicionados.splice(
        this.instrumentoAdicionados.indexOf(permissao),
        1
      );
    },
    verificarExistente(instrumento) {
      return this.instrumentosExistentes.some((el) => el.id === instrumento.id);
    },
    verificarSelecionado(permissao) {
      if (this.tabela.selecionados.some((el) => el.id === permissao.id)) {
        return true;
      } else {
        return false;
      }
      //return this.instrumentoAdicionados.some((el) => el.id === permissao.id);
    },
    checkItemIndividual(itemId) {
      const item = this.form.orcamentoItem.find((i) => i.id === itemId);
      const temAlgumItemSelecionado = item.orcamentoItemDetalhe.some(
        (i) => i.checked
      );
      item.checked = temAlgumItemSelecionado;
    }, */
    buscarFuncionalidadeSelect: async function () {
      await PermissaoService.listar();
    },
    filtroGlobal(v) {
      this.filtroAplicado.filter = v;
    },
    filtrarGlobal() {
      this.listarRegistros();
    },
  },
};
</script>
