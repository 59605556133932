import _ from 'lodash';
export class PapelModel {
  constructor({
    id,
    nome,
    descricao,
    flagAtivo = true,
    flagAdministrador = false,
    papeisPermissoes = []
  }) {
    this.id = id;
    this.nome = nome;
    this.descricao = descricao,
    this.flagAtivo = flagAtivo,
    this.flagAdministrador = flagAdministrador,
    this.papeisPermissoes = papeisPermissoes
  }
    get request() {
      let retorno = _.cloneDeep(this);
      retorno.papeisPermissoes = this.papeisPermissoes.map(item => (
        {
          permissaoId: item.permissaoId
        }
      ));
      return retorno;
  }
}
